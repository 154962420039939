import React, { useEffect, useState } from "react";
import { Col, Form, Row, Dropdown, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { resetErrors } from "../../redux/address/addressSlice";
import {
  fetchAddressDetails,
  fetchAddressList,
} from "../../redux/address/adressThunk";
import Swal from "sweetalert2";
import { Button, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import * as Yup from "yup";
import "../../assets/styles/addressBook/deliverydetails.css";
import { fetchStateName } from "../../redux/landing/landingThunk";

const adressValidation = Yup.object().shape({
  firstName: Yup.string().required("This field is required"),
  // lastName: Yup.string().required("This field is required"),
  country: Yup.string().required("This field is required"),
  Postcode: Yup.string().required("This field is required"),
  address01: Yup.string().required("This field is required"),
  state: Yup.string().required("This field is required"),
  city: Yup.string().required("This field is required"),
  contactcontrycode: Yup.string().required("Required"),
  contactNo: Yup.string()
    .matches(/^\d{10,13}$/, "Mobile number must be between 10 and 13 digits")
    .required("This field is required"),
  email: Yup.string()
    .email("The email must be a valid email address.")
    // .required("This field is required"),
});
const AccordianDelivery = ({
  isFormOpen,
  setIsFormOpen,
  country_data,
  userId,
  serviceData,
  addressListf,
  formDataDelivery,
  setFormDataDelivery,
  setDeliveryEventChange,
  setIsDeliveryValidate,
  setDeliveryValidations,
  setSavedDataDelivery,
  handleContinueClick,
  collectionDates,
  collectionValues,
  selectedCollectionService,
  toSteteData,
  setResidential
}) => {
  const dispatch = useDispatch();

  const uniquesessionid = localStorage.getItem("uniquesessionid");
  const Countryprefix = JSON.parse(localStorage.getItem("Countryprefix"));
  const countrydetails = JSON.parse(localStorage.getItem("searchData"));

  const isSaveLoading = useSelector(
    (state) => state.addressValidation?.isSaveLoading
  );

  const addressToErrors = useSelector(
    (state) => state.addressValidation.delFieldError
  );

  const verificationError = useSelector(
    (state) => state?.addressValidation?.dellValidationError
  );

  const isAddressLoading = useSelector(
    (state) => state.addressValidation?.isAddressLoading
  );

  const [showForm, setShowForm] = useState(true);
  const [isFormDeliveryOpen, setIsFormDeliveryOpen] = useState(false);
  const [selectedOptionDel, setSelectedOptionDel] = useState("Residential");
  const [country_details, setCountry_details] = useState();
  const [filterdAddress, setFilterAddress] = useState("");
  const [addressList, setaddressList] = useState();
  const [contactError, setContactError] = useState("");
  const [serverError, setServerError] = useState({ cityError: "" });
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (addressListf) {
      if (addressListf?.length > 0) {
        setaddressList(addressListf);
      }

      let arrayOfSearchCountry = [country_data];
      const country_code = arrayOfSearchCountry[0]?.toPostalCode;
      const filteredAddressList = addressListf.filter(
        (address) => address?.postcode === country_code
      );
      setFilterAddress(filteredAddressList);
    }
  }, [addressListf,country_data]);

  useEffect(() => {
    if (country_data?.toCountryId) {
      const stateName = country_data?.toCountryId;
      dispatch(fetchStateName(stateName));
    }
  }, [country_data?.toCountryId, dispatch]);

  useEffect(() => {
    if (country_data) {
      setCountry_details(country_data);
      setFormDataDelivery({
        ...formDataDelivery,
        country: country_data.tocountry,
        Postcode: country_data.toPostalCode,
        state: formDataDelivery?.state
          ? formDataDelivery.state
          : serviceData?.cart_details?.delivery_state,
      });
    }
    if (Countryprefix) {
      setFormDataDelivery({
        ...formDataDelivery,
        contact_prefix: `+${Countryprefix?.toPrefix}`,
      });
    }
  }, [country_data]);

  const initialValues = {
    firstName: formDataDelivery?.firstName,
    lastName: formDataDelivery?.lastName,
    companyName: formDataDelivery?.companyName,
    country: formDataDelivery?.country,
    Postcode: formDataDelivery?.Postcode,
    address01: formDataDelivery?.address01,
    address02: formDataDelivery?.address02,
    state: formDataDelivery?.state,
    city: formDataDelivery?.city,
    email: formDataDelivery?.email,
    contactNo: formDataDelivery?.contactNo,
    contactcontrycode: formDataDelivery?.contactcontrycode,
    address_id: formDataDelivery?.address_id,
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredAddresses = (filterdAddress || []).filter((address) => {
    const fullAddress =
      `${address.first_name} ${address.last_name}, ${address.address_1}, ${address.city}, ${serviceData?.cart_details?.delivery_state}`.toLowerCase();
    return fullAddress.includes(searchQuery.toLowerCase());
  });

  const Send = (values) => {
    setServerError("");
    setDeliveryEventChange(false);
    setSavedDataDelivery({
      firstName: values.firstName,
      lastName: values.lastName,
      address01: values.address01,
      address02: values.address02,
      city: values.city,
      state: values.state,
      Postcode: country_data?.toPostalCode,
    });
    if (countrydetails) {
      dispatch(
        fetchAddressDetails({
          ...countrydetails,
          first_name: values.firstName,
          last_name: values.lastName,
          company_name: values.companyName,
          email: values.email,
          phone_number: values.contactNo,
          address_line1: values.address01,
          address_line2: values.address02,
          city: values.city,
          state:
            values.state ||
            (formDataDelivery?.state
              ? formDataDelivery.state
              : serviceData?.cart_details?.delivery_state
                ? serviceData?.cart_details?.delivery_state
                : values.state),
          state_id: "",
          stateCode:
            values.state ||
            (formDataDelivery?.state
              ? formDataDelivery.state
              : serviceData?.cart_details?.delivery_state || values.state),
          cart_id: serviceData?.cart_details?.cart_id,
          cartdetail_id: serviceData?.cart_details?.id,
          postalCode: values.Postcode,
          country_id: country_details?.toCountryId,
          countryCode: country_details?.toCountryCode,
          fromCountryCode: country_details?.fromCountryCode,
          toCountryCode: country_details?.toCountryCode,
          courier_service: serviceData?.cart_details?.courier_service_name,
          courier_service_code: serviceData?.cart_details?.courier_service_code,
          address_type: selectedOptionDel,
          courier_id: serviceData?.cart_details?.courier_id,
          customer_id: userId?.id,
          order_id: serviceData?.cart_details?.order_id,
          address_id: values?.address_id,
          type: "delivery",
          contactcontrycode: values?.contactcontrycode,
          uniquesessionid: uniquesessionid,
        })
      )
        .unwrap()
        .then((response) => {
          if (response.status === true && response.statusCode === 200) {
            if (selectedOptionDel === "Residential") {
              setResidential(true)
            }
            setShowForm(true);
            setDeliveryValidations({
              delivery_validation: "",
            });
            setIsDeliveryValidate(true);
            dispatch(
              fetchAddressList({
                customer_id: userId.id,
                address_id: formDataDelivery?.address_id,
                uniquesessionid: uniquesessionid,
              })
            );

            setFormDataDelivery({
              ...formDataDelivery,
              country: country_details?.tocountry,
              Postcode: country_details?.toPostalCode,
              state: formDataDelivery?.state
                ? formDataDelivery.state
                : serviceData?.cart_details?.delivery_state,
              addressType: selectedOptionDel,
            });

            Swal.fire({
              toast: true,
              icon: "success",
              title: "Delivery Address Saved Successfully",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              showCloseButton: true,
              timer: 1500,
              timerProgressBar: true,
              customClass: {
                timerProgressBar: "custom-progress-bar",
              },
            });
            handleContinueClick("changedelivery");
          }
        })
        .catch((error) => {
          setServerError({
            cityError: error?.data?.collectioncityerror
              ? error?.data?.collectioncityerror
              : error?.data?.errors,
          });
          setIsDeliveryValidate(false);
          if (error.status === 500) {
            Swal.fire({
              icon: "warning",
              title: "Oops...",
              text: "Something went wrong! please try some time latter",
            });
          }
          if (error?.status === 429) {
            Swal.fire({
              icon: "warning",
              title: "Oops...",
              text: "Something went wrong! please try some time latter",
            });
          }
          console.error("profile failed:", error);
        });
    }
    // handleContinueClick("changedelivery")
  };

  const toggleFormDelivery = () => {
    setFormDataDelivery({
      ...formDataDelivery,
      country: country_data.tocountry,
      Postcode: country_data.toPostalCode,
      state: formDataDelivery?.state
        ? formDataDelivery.state
        : serviceData?.cart_details?.delivery_state,
    });
    setShowForm(!showForm);
    setIsFormDeliveryOpen(!isFormDeliveryOpen);
    setIsFormOpen(!isFormOpen);
  };

  const handleOptionChangedelivery = (currentSelection) => {
    if (currentSelection !== selectedOptionDel) {
      if (selectedOptionDel === "Residential") {
        setSelectedOptionDel("Business");
        setFormDataDelivery({
          ...formDataDelivery,
          addressType: "Business",
        });
      } else {
        setSelectedOptionDel("Residential");
        setFormDataDelivery({
          ...formDataDelivery,
          addressType: "Residential",
        });
      }
    }
  };

  const handleResetFormDelivery = () => {
    dispatch(resetErrors());

    setFormDataDelivery({
      // country: "",
      // Postcode: "",
      firstName: "",
      lastName: "",
      companyName: "",
      address01: "",
      address02: "",
      state: !toSteteData && "",
      city: "",
      email: "",
      contact_prefix: "",
      contactcontrycode: "",
      contactNo: "",
      // addressType: "",
    });
    setSelectedOptionDel("Residential");
  };
  const handleDropdownSelect = (address) => {
    setSearchQuery("");

    dispatch(resetErrors());

    setShowForm(true);

    setFormDataDelivery({
      ...formDataDelivery,
      country: country_data?.tocountry,
      Postcode: country_data?.toPostalCode,
      firstName: address.first_name,
      lastName: address.last_name,
      companyName: address.company_name,
      address01: address.address_1,
      address02: address.address_2,
      state: address?.state
        ? address.state
        : serviceData?.cart_details?.delivery_state,
      city: address.city,
      email: address.email,
      contactcontrycode: address?.contact_extention,
      contactNo: address?.contact_no,
      contact_prefix: address?.contact_extention,
      addressType: selectedOptionDel,
      address_id: address?.id,
    });
  };

  const handleSelectChange = (event) => {
    const selectedState = event.target.value;
    setFormDataDelivery({
      ...formDataDelivery,
      state: selectedState,
    });
  };

  const handleCustomChange = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFormDataDelivery({
      ...formDataDelivery,
      [name]: value,
    });
    setFieldValue(name, value);
  };

  return (
    <>
      <div className="address-outer delivery-outer address-delivery-outer-holder">
        <div className="c w-100 d-flex  flex-column gap-4">
          <div className="w-100 delivery-row gap-4 flex-column flex-lg-row pt-4">
            <div style={{ flex: "1" }} className="">
              <div
                style={{ border: "1px solid #e9e9e9" }}
                className={`address-bg d-flex px-4 box-shadow is-valid-paper ${isFormDeliveryOpen ? "open" : ""
                  }`}
                onClick={toggleFormDelivery}
              >
                <img
                  src={`${window.location.origin}/assets/${isFormDeliveryOpen ? "minusblue" : "plusblue"
                    }.svg`}
                  alt="Toggle Icon"
                />
                {(!isFormDeliveryOpen || isFormDeliveryOpen) && (
                  <p className=" mb-0 px-2">Add New Address</p>
                )}
              </div>
            </div>
            <div style={{ flex: "1" }}>
              <div
                className="address-bg address-bg-delivery box-shadow w-100 is-valid-paper"
                style={{ border: "1px solid #e9e9e9" }}
              >
                <Dropdown className="w-100">
                  <Dropdown.Toggle
                    id="dropdown-button-dark-example1"
                    variant="secondary"
                    className="d-flex dropdown-toggle py-0 w-100 justify-content-between align-items-center border-0"
                  >
                    <div>
                      <p className="mb-0 select-input">
                        Select From Saved Address
                      </p>
                    </div>
                    <div className="dropdown-icon">
                      <img
                        src={`${window.location.origin}/assets/dropdownicon.svg`}
                        alt="Dropdown"
                      />
                    </div>
                  </Dropdown.Toggle>
                  {isAddressLoading ? (
                    <Box sx={{ width: 360 }}>
                      <Skeleton />
                      <Skeleton animation="wave" />
                    </Box>
                  ) : (
                    <Dropdown.Menu className="dropdown-menu-delivery">
                      <div className="p-3">
                        <Form.Control
                          type="text"
                          placeholder="Search address"
                          value={searchQuery}
                          onChange={handleSearchChange}
                          className="is-valid-paper"
                        />
                      </div>
                      {filteredAddresses.length === 0 ? (
                        <Dropdown.Item>No Address Found...</Dropdown.Item>
                      ) : (
                        filteredAddresses.map((address, index) => (
                          <Dropdown.Item
                            className="address-dropdown-item"
                            key={index}
                            onClick={() => handleDropdownSelect(address)}
                          >
                            {`${address.first_name} ${address.last_name}, ${address.address_1}, ${address.city}, ${serviceData?.cart_details?.delivery_state}`}
                          </Dropdown.Item>
                        ))
                      )}
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              </div>
            </div>
          </div>
          <div className=" d-lg-flex">
            {showForm && (
              <>
                <div className="d-flex">
                  <div
                    className="add-form d-flex gap-4 flex-column mb-4"
                    style={{ flex: "3" }}
                  >
                    <div className="w-sm-75 w-lg-75">
                      <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={adressValidation}
                        onSubmit={Send}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          handleReset,
                          setFieldValue,
                          isSubmitting,
                          isValid,
                        }) => (
                          <Form
                            className="d-flex flex-column gap-4"
                            onSubmit={handleSubmit}
                            onReset={handleReset}
                          >
                            <Row className=" flex-column flex-sm-row flex-lg-row gap-4 gap-md-0 gap-lg-0">
                              <Col
                                md="5"
                                lg="5"
                                className="collect-form-address"
                              >
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    className="input-main-all"
                                    name="Country"
                                    value={values.country}
                                    placeholder="Enter Country"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled
                                  />
                                </Form.Group>
                              </Col>

                              <Col md="5" lg="3">
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    className="input-main-all"
                                    name="Postcode"
                                    value={values.Postcode}
                                    placeholder="Enter Postcode"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="flex-column flex-lg-row flex-sm-row gap-4 gap-md-0  gap-lg-0">
                              <Col md="4">
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    className={`input-main-all ${errors.firstName &&
                                      touched.firstName &&
                                      errors.firstName
                                      ? "is-invalid-paper"
                                      : "is-valid-paper"
                                      }`}
                                    name="firstName"
                                    value={values.firstName}
                                    placeholder="First name"
                                    onChange={(e) => {
                                      handleCustomChange(e, setFieldValue);
                                      setDeliveryEventChange(true);
                                    }}
                                    onKeyPress={(e) => {
                                      if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      errors.firstName &&
                                      touched.firstName &&
                                      errors.firstName
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    <span>
                                      {errors.firstName &&
                                        touched.firstName &&
                                        errors.firstName}
                                    </span>
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col md="4">
                                <Form.Group>
                                  <Form.Control
                                    className={`input-main-all ${errors.lastName &&
                                      touched.lastName &&
                                      errors.lastName
                                      ? "is-invalid-paper"
                                      : "is-valid-paper"
                                      }`}
                                    type="text"
                                    name="lastName"
                                    value={values.lastName}
                                    placeholder="Last name"
                                    onChange={(e) => {
                                      setDeliveryEventChange(true);
                                      handleCustomChange(e, setFieldValue);
                                    }}
                                    onKeyPress={(e) => {
                                      if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      errors.lastName &&
                                      touched.lastName &&
                                      errors.lastName
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    <span>
                                      {errors.lastName &&
                                        touched.lastName &&
                                        errors.lastName}
                                    </span>
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col md="4">
                                <Form.Group>
                                  <Form.Control
                                    className={`input-main-all ${errors.companyName &&
                                      touched.companyName &&
                                      errors.companyName
                                      ? "is-invalid-paper"
                                      : "is-valid-paper"
                                      }`}
                                    type="text"
                                    name="companyName"
                                    value={values.companyName}
                                    placeholder="Company Name"
                                    onChange={(e) => {
                                      setDeliveryEventChange(true);
                                      handleCustomChange(e, setFieldValue);
                                    }}
                                    onKeyPress={(e) => {
                                      if (!/^[a-zA-Z\s.]*$/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Form.Group>
                                <Col md="12">
                                  <Form.Control
                                    type="text"
                                    className={`input-main-all ${errors.address01 &&
                                      touched.address01 &&
                                      errors.address01
                                      ? "is-invalid-paper"
                                      : "is-valid-paper"
                                      }`}
                                    name="address01"
                                    value={values.address01}
                                    placeholder="Address01"
                                    onChange={(e) => {
                                      setDeliveryEventChange(true);
                                      handleCustomChange(e, setFieldValue);
                                    }}
                                    onKeyPress={(e) => {
                                      if (!/^[a-zA-Z0-9\s,]*$/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      errors.address01 &&
                                      touched.address01 &&
                                      errors.address01
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    <span>
                                      {errors.address01 &&
                                        touched.address01 &&
                                        errors.address01}
                                    </span>
                                  </Form.Control.Feedback>
                                </Col>
                              </Form.Group>
                            </Row>
                            <Row className="d-flex flex-column flex-lg-row gap-4 gap-lg-0">
                              <Form.Group>
                                <Col md="12">
                                  <Form.Control
                                    className={`input-main-all ${errors.address02 &&
                                      touched.address02 &&
                                      errors.address02
                                      ? "is-invalid-paper"
                                      : "is-valid-paper"
                                      }`}
                                    type="text"
                                    name="address02"
                                    value={values.address02}
                                    placeholder="Address02"
                                    onChange={(e) => {
                                      setDeliveryEventChange(true);
                                      handleCustomChange(e, setFieldValue);
                                    }}
                                    onKeyPress={(e) => {
                                      if (!/^[a-zA-Z0-9\s,]*$/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </Col>
                              </Form.Group>
                            </Row>
                            <Row className=" flex-column flex-lg-row flex-sm-row gap-md-0 gap-4 gap-lg-0">
                              <Col md={6}>
                                {/* <Form.Group>
                                  <Form.Control
                                    className={`input-main-all ${errors.state && touched.state && errors.state ? "is-invalid-paper" : "is-valid-paper"}`}
                                    type="state"
                                    name="state"
                                    value={values.state}
                                    placeholder="state"
                                    onChange={(e) => {
                                      setDeliveryEventChange(true);
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </Form.Group> */}
                                {!toSteteData && (
                                  <Form.Group>
                                    <Form.Control
                                      className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.state &&
                                        touched.state &&
                                        errors.state
                                        ? "is-invalid-paper"
                                        : "is-valid-paper"
                                        }`}
                                      type="text"
                                      name="state"
                                      placeholder="State"
                                      value={values.state}
                                      onChange={(e) => {
                                        handleCustomChange(e, setFieldValue);
                                      }}
                                      onKeyPress={(e) => {
                                        if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onBlur={handleBlur}
                                      isInvalid={errors.state && touched.state}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      <span>
                                        {errors.state &&
                                          touched.state &&
                                          errors.state}
                                      </span>
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                )}
                                {toSteteData && (
                                  <Form.Group controlId="formGridfirstname">
                                    <Form.Select
                                      aria-label="Default select example"
                                      placeholder="Select"
                                      onBlur={handleBlur}
                                      className={`Pickup-select-input shadow-none  ${errors.state &&
                                        touched.state &&
                                        errors.state
                                        ? "is-invalid-paper"
                                        : "is-valid-paper" &&
                                        formDataDelivery?.state ===
                                        "Select" &&
                                        "pickup-placeholder-select"
                                        }`}
                                      onChange={handleSelectChange}
                                      value={formDataDelivery.state}
                                      isInvalid={
                                        errors.state &&
                                        touched.state &&
                                        errors.state
                                      }
                                    >
                                      {toSteteData?.map((option, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={option?.code}
                                          >
                                            {option?.default_name}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                    {errors?.state && (
                                      <Form.Text
                                        className="text-danger mt-0"
                                        type="invalid"
                                      >
                                        {errors.state &&
                                          touched.state &&
                                          errors.state}
                                      </Form.Text>
                                    )}
                                    {/* vvdvdvd */}
                                    <Form.Control.Feedback type="invalid">
                                      <span>
                                        {errors.state &&
                                          touched.state &&
                                          errors.state}
                                      </span>
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                )}
                              </Col>
                              <Col md={6}>
                                <Form.Group>
                                  <Form.Control
                                    className={`input-main-all ${errors.city && touched.city && errors.city
                                      ? "is-invalid-paper"
                                      : "is-valid-paper"
                                      }`}
                                    type="text"
                                    name="city"
                                    value={values.city}
                                    placeholder="Enter City"
                                    onChange={(e) => {
                                      setDeliveryEventChange(true);
                                      handleCustomChange(e, setFieldValue);
                                    }}
                                    onKeyPress={(e) => {
                                      if (!/^[a-zA-Z]$/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      errors.city && touched.city && errors.city
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    <span>
                                      {errors.city &&
                                        touched.city &&
                                        errors.city}
                                    </span>
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className=" flex-column flex-lg-row flex-sm-row  gap-4  gap-md-0  gap-lg-0">
                              <Col md={6}>
                                <Form.Group>
                                  <Form.Control
                                    className={`input-main-all ${errors.email &&
                                      touched.email &&
                                      errors.email
                                      ? "is-invalid-paper"
                                      : "is-valid-paper"
                                      }`}
                                    type="text"
                                    name="email"
                                    value={values.email}
                                    placeholder="Enter Email"
                                    onChange={(e) => {
                                      setDeliveryEventChange(true);
                                      handleCustomChange(e, setFieldValue);
                                    }}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      errors.email &&
                                      touched.email &&
                                      errors.email
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    <span>
                                      {errors.email &&
                                        touched.email &&
                                        errors.email}
                                    </span>
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Row>
                                  <Col md={3} xs={3} className="pe-0 pe-sm-2">
                                    <Form.Control
                                      className={`input-main-all ${errors.contactcontrycode &&
                                        touched.contactcontrycode &&
                                        errors.contactcontrycode
                                        ? "is-invalid-paper"
                                        : "is-valid-paper"
                                        }`}
                                      type="number"
                                      name="contactcontrycode"
                                      placeholder="+91"
                                      minLength={1}
                                      maxLength={4}
                                      value={values.contactcontrycode}
                                      onBlur={handleBlur}
                                      onChange={(e) => {
                                        setDeliveryEventChange(true);
                                        handleCustomChange(e, setFieldValue);
                                      }}
                                      onKeyPress={(e) => {
                                        if (!/^[0-9+]+$/.test(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                      isInvalid={
                                        errors.contactcontrycode &&
                                        touched.contactcontrycode &&
                                        errors.contactcontrycode
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      <span>
                                        {errors.contactcontrycode &&
                                          touched.contactcontrycode &&
                                          errors.contactcontrycode}
                                      </span>
                                    </Form.Control.Feedback>
                                  </Col>
                                  <Col md={9} xs={9}>
                                    <Form.Control
                                      className={`user-address-placeholder input-main-all ${errors.contactNo &&
                                        touched.contactNo &&
                                        errors.contactNo
                                        ? "is-invalid-paper"
                                        : "is-valid-paper"
                                        }`}
                                      type="number"
                                      placeholder="contact No"
                                      name="contactNo"
                                      value={values.contactNo}
                                      onChange={(e) => {
                                        setDeliveryEventChange(true);
                                        handleCustomChange(e, setFieldValue);
                                      }}
                                      onKeyPress={(e) => {
                                        if (!/^[0-9]*$/.test(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onWheel={event => event.currentTarget.blur()}
                                      pattern="[0-9]*"
                                      onBlur={handleBlur}
                                      isInvalid={
                                        errors.contactNo &&
                                        touched.contactNo &&
                                        errors.contactNo
                                      }
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      <span>
                                        {errors.contactNo &&
                                          touched.contactNo &&
                                          errors.contactNo}
                                      </span>
                                    </Form.Control.Feedback>
                                  </Col>
                                </Row>
                                <p className="profile_address-error">
                                  {addressToErrors?.phone_number}
                                </p>
                                <p className="profile_address-error">
                                  {contactError}
                                </p>
                              </Col>
                            </Row>

                            <div className="radiobtnAddress d-flex gap-2">
                              <div className="text-nowrap">
                                <p className="">Select address type :</p>
                              </div>
                              <div className="radio-holderaddress custom_radio p-0 d-flex flex-row gap-2">
                                <div
                                  className="col d-flex gap-1"
                                  onClick={(e) =>
                                    handleOptionChangedelivery("Residential")
                                  }
                                >
                                  <input
                                    className="chek"
                                    type="radio"
                                    id="Residential"
                                    name="select1"
                                    value="Residential"
                                    checked={
                                      selectedOptionDel === "Residential"
                                    }
                                  />
                                  <label for="Residential">Residential</label>
                                </div>
                                <div
                                  className=" col d-flex gap-1"
                                  onClick={(e) =>
                                    handleOptionChangedelivery("Business")
                                  }
                                >
                                  <input
                                    className=""
                                    type="radio"
                                    id="Business"
                                    name="select1"
                                    value="Business"
                                    checked={selectedOptionDel === "Business"}
                                  />
                                  <label for="Business">Business</label>
                                </div>
                              </div>
                            </div>
                            <div>
                              {(verificationError || serverError?.cityError) && (
                                <p className="error-text text-danger">
                                  {verificationError ? verificationError : serverError?.cityError}
                                </p>
                              )}
                            </div>
                            <div className="d-flex gap-4 py-0 ">
                              <div style={{ flex: "1" }}>
                                {!isSaveLoading ? (
                                  <Button
                                    className="primarybtn btn w-100 py-2 input-main-all h-100 text-nowrap"
                                    type="submit"
                                  >
                                    Save and Confirm Address
                                  </Button>
                                ) : (
                                  <Button className="primarybtn btn h-100 w-100 py-2">
                                    <Spinner animation="border" size="sm" />
                                  </Button>
                                )}
                              </div>
                              <div
                                style={{ flex: "2" }}
                                className="save-collection"
                              >
                                <Button
                                  variant="link"
                                  className="primary_btn_link btn input-main-all h-100"
                                  onClick={handleResetFormDelivery}
                                  type="reset"
                                >
                                  Reset
                                </Button>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
                <div style={{ flex: "0" }}></div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccordianDelivery;
