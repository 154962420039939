import React, { useEffect, useState } from "react";
import { Col, Form, Row, Card, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deactiveAccount,
  fetchProfileDetails,
  getparcellabelsize,
} from "../../redux/user_profile/ProfileThunk";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { resetErrors } from "../../redux/user_profile/ProfileSlice";
import { resetErrors as addressreseterror } from "../../redux/address/addressSlice";
import { Formik, Field } from "formik";
import "../../assets/styles/userProfile/userProfile.css";
import * as Yup from "yup";
import { IconButton, Tooltip, Button } from "@mui/material";
import SwalFireComponent from "../common/SwalFireComponent";

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Too Short!")
    .max(20, "Too Long!")
    .required("This field is required"),
  // last_name: Yup.string()
  //   .min(2, "Too Short!")
  //   .max(20, "Too Long!")
  //   .required("This field is required"),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Mobile number must be 10 digits")
    .required("This field is required"),
  phone_prefix: Yup.string().required("Required"),
  gender: Yup.string().required("This field is required"),
});

const UserProfile = ({ setdatachange }) => {
  const navigate = useNavigate();
  const userData = localStorage.getItem("user");
  const userObject = JSON.parse(userData);
  const profileErrors = useSelector((state) => state.profileData.error);
  const dispatch = useDispatch();
  const errors = useSelector((state) => state.profileData.profileData?.data);

  const isProfileLoading = useSelector(
    (state) => state.profileData.isProfileLoading
  );
  const user_id = userObject?.id;

  const uniquesessionid = localStorage.getItem("uniquesessionid");

  const [editInput, setEditInput] = useState(false);

  const handleEditClick = () => {
    setEditInput(!editInput);
  };

  const handleDeactive = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success me-3 px-4 confirmButton",
        cancelButton: "btn btn-danger me-2 px-4",
      },
      buttonsStyling: false,
      confirmButtonColor: "#113468",
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to deactivate account?",
        text: "After deactivating your acocunt you will no longer have access to our services",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#113468",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire({
            title: "Deactive",
            text: "You are not longer to our service to activate account again need to contact us",
            icon: "warning",
          });
          dispatch(
            deactiveAccount({
              user_id: user_id,
              uniquesessionid: uniquesessionid,
            })
          )
            .unwrap()
            .then((response) => {
              if (
                response?.AccountDeactivate?.status === "success" &&
                response?.AccountDeactivate?.code === "200"
              ) {
                SwalFireComponent({
                  icon: "error",
                  title: "Account deactivated",
                });
                setTimeout(() => {
                  localStorage.clear();
                  navigate("/");
                }, 1000);
              }
              // if (response?.AccountDeactivate?.status === "fail" && response?.AccountDeactivate?.code === "422") {
              //   swalWithBootstrapButtons.fire({
              //     title: "Deactive",
              //     text: "Account is already deactivated",
              //     icon: "warning",
              //   });
              // }
            })
            .catch((error) => {
              console.error("profile failed:", error);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Your Deactivation Process has been cancelled.",
            icon: "error",
          });
        }
      });
    const deactiveFinal = false;
    if (deactiveFinal) {
    }
  };

  const handleResetClick = (resetForm) => {
    resetForm({
      values: {
        first_name: "",
        last_name: "",
        email: Object.email,
        phone: "",
        gender: "",
        phone_prefix: "",
        checked: "",
      },
    });
  };

  useEffect(() => {
    dispatch(addressreseterror());
  }, [dispatch]);

  return (
    <div className="userprofile-outer">
      <Card className="border-0">
        <Card.Body className="d-flex flex-column gap-3 ps-4">
          <Formik
            initialValues={{
              first_name: userObject?.first_name,
              last_name: userObject?.last_name,
              email: userObject?.email,
              phone: userObject?.phone,
              gender: userObject?.gender,
              user_id: userObject?.user_id,
              phone_prefix: userObject?.phone_prefix,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              dispatch(
                fetchProfileDetails({
                  first_name: values.first_name,
                  last_name: values.last_name,
                  email: userObject?.email,
                  phone: values.phone,
                  gender: values.gender,
                  phone_prefix: values.phone_prefix,
                  user_id: user_id,
                  customer_id: user_id,
                  uniquesessionid: uniquesessionid,
                })
              )
                .unwrap()
                .then((response) => {
                  if (
                    response?.Profile?.status === "success" &&
                    response?.Profile?.code === "200"
                  ) {
                    localStorage.setItem(
                      "user",
                      JSON.stringify(response?.Profile?.data)
                    );
                    setdatachange(response?.data);
                    setEditInput(false);
                    Swal.fire({
                      toast: true,
                      icon: "success",
                      title: "Profile Updated",
                      animation: true,
                      position: "top-right",
                      showConfirmButton: false,
                      showCloseButton: true,
                      timer: 2000,
                      timerProgressBar: true,
                      customClass: {
                        timerProgressBar: "custom-progress-bar",
                      },
                    });
                  }
                })
                .catch((error) => {
                  console.error("profile failed:", error);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              resetForm,
            }) => (
              <Form
                onSubmit={handleSubmit}
                onChange={handleChange}
                onReset={() => handleResetClick(resetForm)}
              >
                <div className="d-flex flex-column gap-4">
                  <div className="d-flex gap-2 gap-lg-4 gap-sm-5">
                    <div className="text-nowrap">
                      <h5 className="m-0 personal-text">
                        Personal Information
                      </h5>
                    </div>
                    <div>
                      {editInput ? (
                        <></>
                      ) : (
                        <Tooltip title="Edit" className="pointer-icon">
                          <IconButton className="p-0" onClick={handleEditClick}>
                            <>
                              <img
                                className="pointer-icon"
                                alt="icon"
                                src={`${window.location.origin}/assets/address-icons/edit.svg`}
                              />
                            </>
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </div>

                  <div className="d-flex gap-4 w-75 flex-column flex-lg-row userprofile-inputs-holder">
                    <div className="" style={{ flex: "1" }}>
                      <Form.Group controlId="formGridfirstname">
                        <Form.Label>
                          <p className="m-0 align-self-center">First Name</p>
                        </Form.Label>
                        <Form.Control
                          className={`input-main-all  ${
                            (!editInput && "disabled_font") ||
                            (errors?.first_name
                              ? "is-invalid-paper"
                              : "is-valid-paper")
                          }`}
                          type="text"
                          name="first_name"
                          placeholder="Enter First Name"
                          onBlur={handleBlur}
                          value={values.first_name}
                          disabled={!editInput}
                          onKeyPress={(e) => {
                            if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          isInvalid={
                            (profileErrors?.first_name &&
                              profileErrors?.first_name.length > 0) ||
                            (errors.first_name &&
                              touched.first_name &&
                              errors.first_name)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {profileErrors?.first_name &&
                            profileErrors?.first_name?.map((msg, index) => (
                              <span className="p.paragraph2">{msg}</span>
                            ))}
                          {errors?.first_name &&
                            touched.first_name &&
                            errors.first_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className=" " style={{ flex: "1" }}>
                      <Form.Group controlId="formGridlastname">
                        <Form.Label>
                          <p className="m-0 align-self-center">Last Name</p>
                        </Form.Label>
                        <Form.Control
                          className={`input-main-all   ${
                            (!editInput && "disabled_font") ||
                            (errors?.last_name
                              ? "is-invalid-paper"
                              : "is-valid-paper")
                          }`}
                          type="text"
                          name="last_name"
                          placeholder="Enter Last Name"
                          value={values.last_name}
                          onBlur={handleBlur}
                          disabled={!editInput}
                          onKeyPress={(e) => {
                            if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          isInvalid={
                            (profileErrors?.last_name &&
                              profileErrors?.last_name.length > 0) ||
                            (errors.last_name &&
                              touched.last_name &&
                              errors.last_name)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {profileErrors?.last_name &&
                            profileErrors?.last_name?.map((msg, index) => (
                              <span className="p.paragraph2">{msg}</span>
                            ))}
                          {errors.last_name &&
                            touched.last_name &&
                            errors.last_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="radio-holderaddress radiobtnAddress radio-parcel d-flex gap-2 align-items-start w-100 w-lg-75">
                    <div className=" ">
                      <div className="  text-nowrap">
                        <p>Your Gender:</p>
                      </div>
                    </div>
                    <div className=" w-100 userprofileradio">
                      <div className="d-flex flex-column flex-sm-column flex-md-row custom_radio">
                        <div className="d-flex gap-3 gap-sm-0">
                          <div className="d-flex gap-1">
                            <input
                              type="radio"
                              name="gender"
                              id="maleRadio"
                              value="Male"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="radio-check-my-ship-details d-flex align-items-center gap-2"
                              disabled={!editInput}
                              checked={values.gender === "Male"}
                            />
                            <label htmlFor="maleRadio">Male</label>
                          </div>
                          <div className="d-flex gap-1">
                            <input
                              type="radio"
                              name="gender"
                              id="femaleRadio"
                              value="Female"
                              className="radio-check-my-ship-details d-flex align-items-center gap-2 "
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={!editInput}
                              checked={values.gender === "Female"}
                            />
                            <label htmlFor="femaleRadio">Female</label>
                          </div>
                        </div>
                        <div className="d-flex text-nowrap">
                          <div className="col d-flex gap-1">
                            <input
                              type="radio"
                              name="gender"
                              id="Non-binary"
                              value="Non-binary"
                              className="radio-check-my-ship-details d-flex align-items-center gap-2"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={!editInput}
                              checked={values.gender === "Non-binary"}
                            />
                            <label htmlFor="Non-binary">Non-binary</label>
                          </div>
                        </div>
                        <div className="d-flex text-nowrap custom_radio">
                          <div className="col d-flex gap-1">
                            <input
                              type="radio"
                              name="gender"
                              id="PreferNottoSay"
                              value="Prefer Not to Say"
                              className="radio-check-my-ship-details d-flex align-items-center gap-2 custom_radio"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={!editInput}
                              checked={values.gender === "Prefer Not to Say"}
                            />
                            <label htmlFor="PreferNottoSay">
                              Prefer Not to Say
                            </label>
                          </div>
                        </div>
                      </div>
                      <span className="profile_address-error">
                        {errors.gender && touched.gender && errors.gender}
                      </span>
                    </div>
                  </div>

                  <div className="d-flex gap-4  w-75 flex-column flex-lg-row userprofile-inputs-holder">
                    <div className="" style={{ flex: "1" }}>
                      <Form.Group controlId="formGridEmail">
                        <Form.Label>
                          <p className="m-0 align-self-center">Email Address</p>
                        </Form.Label>
                        <Form.Control
                          className={`input-main-all   ${
                            (!editInput && "disabled_font") ||
                            (errors?.email
                              ? "is-invalid-paper"
                              : "is-valid-paper")
                          }`}
                          type="email"
                          name="email"
                          placeholder="Enter email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={false}
                          disabled={true}
                        />
                      </Form.Group>
                    </div>
                    <div style={{ flex: "1" }}>
                      <div className="d-flex ">
                        <div className="w-25">
                          <div className="pe-0 w-75">
                            <Form.Group controlId="formPrefix">
                              <Form.Label>
                                <p className="m-0 align-self-center">Prefix</p>
                              </Form.Label>
                              <Form.Control
                                // className="user-address-placeholder input-main-all is-valid-paper "
                                className={`user-address-placeholder input-main-all  ${
                                  (!editInput && "disabled_font") ||
                                  (errors?.phone_prefix
                                    ? "is-invalid-paper"
                                    : "is-valid-paper")
                                }`}
                                type="number"
                                name="phone_prefix"
                                placeholder="+91"
                                value={values.phone_prefix}
                                isInvalid={
                                  errors.phone_prefix &&
                                  touched.phone_prefix &&
                                  errors.phone_prefix
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyPress={(e) => {
                                  if (!/^[0-9+]*$/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                pattern="[0-9]*"
                                disabled={!editInput}
                              ></Form.Control>
                              <Form.Control.Feedback type="invalid">
                                {errors.phone_prefix &&
                                  touched.phone_prefix &&
                                  errors.phone_prefix}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>
                        <div className="w-75">
                          <Form.Group>
                            <Form.Label>
                              <p className="m-0 align-self-center">
                                Mobile Number
                              </p>
                            </Form.Label>
                            <Form.Control
                              className={`user-address-placeholder input-main-all   ${
                                (!editInput && "disabled_font") ||
                                (errors?.phone
                                  ? "is-invalid-paper"
                                  : "is-valid-paper")
                              }`}
                              type="number"
                              placeholder="Enter Mobile Number"
                              name="phone"
                              value={values.phone}
                              isInvalid={errors.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onKeyPress={(e) => {
                                if (!/^[0-9]*$/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onWheel={(event) => event.currentTarget.blur()}
                              pattern="[0-9]*"
                              disabled={!editInput}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.phone && touched.phone && errors.phone}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>
                      <p className="profile_address-error">
                        {profileErrors?.phone_number}
                      </p>
                    </div>
                  </div>

                  {editInput && (
                    <div className="d-flex gap-4 gap-sm-3 profile-Buttons">
                      <>
                        <div className="profile-resetButton">
                          <Button
                            variant="link"
                            type="reset"
                            className="blue-text"
                            style={{ height: "40px", color: "#113468" }}
                          >
                            Reset
                          </Button>
                        </div>
                        <div className="profile-SaveButton">
                          <Button
                            style={{ height: "40px" }}
                            variant="primary"
                            type="submit"
                            className="blue-text primarybtn btn savebtn-user w-100 text-nowrap"
                          >
                            {!isProfileLoading ? (
                              <span>Save</span>
                            ) : (
                              <div className="px-2 ">
                                <Spinner animation="border" size="sm" />
                              </div>
                            )}
                          </Button>
                        </div>
                      </>
                    </div>
                  )}
                </div>

                <div className="">
                  <hr className="mt-4 mb-2" />
                </div>

                <div className="lh-1">
                  <Button
                    variant="link blue-text p-2"
                    style={{ fontWeight: "500", color: "#113468" }}
                    onClick={handleDeactive}
                  >
                    {/* <p className="paragraph2 font-weight-bold lh-1"> */}
                    Deactivate Account
                    {/* </p> */}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UserProfile;
