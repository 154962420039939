/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Card, Col, Dropdown, Form, Modal, Row, Spinner, } from "react-bootstrap";
import "../../assets/styles/userProfile/userAddressBook.css";
import "../../assets/styles/userProfile/userSavedCard.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteAddress, fetchUserAddressDetails, fetchAddressList, bulkAddress, } from "../../redux/address/adressThunk";
import { fetchCountryDetails, fetchPostalDetails, fetchStateName } from "../../redux/landing/landingThunk";
import { Button, Autocomplete, IconButton, Paper, Skeleton, Tooltip, Box, Pagination } from "@mui/material";
import { resetErrors, resetErrorsAddress } from "../../redux/address/addressSlice";
import Swal from "sweetalert2";
import CustomAutocomplete from "../common/CustomCountryPostal";
import UserSkeleton from "./UserSkeleton";
import { Logger } from "ag-grid-community";
import { bulkOrders } from "../../redux/services/ServicesThunk";
import { useNavigate } from "react-router-dom";
import SwalFireComponent from "../common/SwalFireComponent";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";

const emailRegex =
  /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/i;
const adressValidation = Yup.object().shape({
  firstName: Yup.string().required("This field is required"),
  // lastName: Yup.string().required("This field is required"),
  country: Yup.string().required("Please select valid country"),
  postal: Yup.string().required("Please enter valid postal"),
  address01: Yup.string().required("This field is required"),
  city: Yup.string().required("This field is required"),
  // state: Yup.string().required("This field is required ss"),
  contact_extention: Yup.string().required("Required"),
  contactNo: Yup.string()
    .matches(/^\d{10}$/, "Mobile number must be 10 digits")
    .required("This field is required"),
  email: Yup.string()
    .matches(emailRegex, "The email must be a valid email address.")
    // .required("This field is required"),
});

const AddressCard = ({ userId, country_Name, bulkUserAddRow, setshowAddressModal }) => {
  const contentRef = useRef(null);

  const uniquesessionid = localStorage.getItem("uniquesessionid");
  const addressErrors = useSelector(
    (state) => state?.addressValidation?.profileFieldError
  );
  const deleteLoading = useSelector((state) => state?.addressValidation);
  const defaultLoading = useSelector(
    (state) => state?.addressValidation?.isSaveLoading
  );
  const country_Postal = useSelector(
    (state) => state.landingReducer?.countryPostal?.data
  );
  const listAddress = useSelector(
    (state) => state.addressValidation?.addressList?.CustomerAddress?.data
  );
  const isPostalLoading = useSelector(
    (state) => state.landingReducer.isPostalLoading
  );
  const isSaveLoading = useSelector(
    (state) => state.addressValidation?.isSaveLoading
  );
  const isStateLoading = useSelector(
    (state) => state?.landingReducer?.isStateLoading
  );

  const isAddressLoading = useSelector(
    (state) => state.addressValidation?.isAddressLoading
  );
  const isBulkAddressLoading = useSelector(
    (state) => state?.servicedata?.isbulkAddressLoading
  );

  const state_name = useSelector(
    (state) => state.landingReducer?.stateName?.data
  );

  const dispatch = useDispatch();
  const [countryName, setCountryName] = useState();
  const [statenames, setStatenames] = useState();
  const [selectedOption, setSelectedOption] = useState("Residential");
  const [filteredAddress, setFilterAddress] = useState(listAddress);
  const [showForm, setShowForm] = useState(false);
  const [isFormCollectionOpen, setIsFormCollectionOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteID] = useState(null);
  const [DefaultId, setDefaultId] = useState(null);
  const [allErros, setAllErrors] = useState();
  const [lineErrors, setLineErrors] = useState();
  const [stateError, setStateError] = useState("");
  const [frompostalLength, setFrompostalLength] = useState();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    country: "",
    Postcode: "",
    companyName: "",
    address01: "",
    address02: "",
    state: "",
    city: "",
    email: "",
    contactNo: "",
    contact_extention: "",
    contact_prefix: "",
    country_id: "",
    country_code: "",
    address_type: selectedOption,
    address_id: "",
    addressInput: "",
    state_sort_name: "",
  });
  const [backenderror, setBackenderror] = useState({
    country: "",
    Postcode: "",
    firstName: "",
    lastName: "",
    address01: "",
    state: "",
    city: "",
    contact_extention: ""
  });
  const [formErrors, setFormErrors] = useState({
    country: "",
    Postcode: "",
  });

  const [newFormStateErrors, setNewFormStateErrors] = useState("")
  const initialValues = {
    firstName: formData?.firstName,
    lastName: formData?.lastName,
    companyName: formData?.companyName,
    country: formData?.country,
    postal: formData?.Postcode,
    address01: formData?.address01,
    address02: formData?.address02,
    state: formData?.state_sort_name || formData?.state,
    // state: !statenames ? formData?.state : formData?.state_sort_name,
    state_sort_name: statenames ? formData?.state_sort_name : formData?.state,
    city: formData?.city,
    email: formData?.email,
    contactNo: formData?.contactNo,
    address_type: formData?.address_type,
    contact_extention: formData?.contact_extention,
    country_id: formData?.country_id,
  };

  const Send = (values) => {
    if (!statenames && (formData?.state?.trim() === "" || formData?.state === undefined)) {
      setNewFormStateErrors("This field is required");
      return;
    }

    const hasErrors = Object.values(backenderror).some((error) => error !== "" && error !== undefined);
    if (!hasErrors) {
      if (formData?.state_sort_name || formData?.state !== "" || formData?.state !== undefined) {
        const countryid = country_Name?.find(
          (cntr) => cntr?.name.toLowerCase() === values?.country.toLowerCase()
        );
        if (countryid) {
          const addressData = {
            first_name: values?.firstName,
            last_name: values?.lastName,
            company_name: values?.companyName,
            email: values?.email,
            contact_extention: values?.contact_extention ? values?.contact_extention : "",
            contact_no: values?.contactNo,
            address_1: values?.address01,
            address_2: values?.address02 ? values?.address02 : "",
            city: values?.city,
            state: formData?.state_sort_name ? formData?.state_sort_name : formData?.state,
            address_validation: false,
            address_type: selectedOption,
            country: values?.country,
            customer_id: userId?.id,
            postcode: values?.postal,
            country_id: countryid?.id,
            address_id: formData?.address_id,
            uniquesessionid: uniquesessionid,
          };

          dispatch(
            fetchUserAddressDetails({
              addressData,
              store: `${addressData?.address_id ? "update" : "store"}`,
            })
          )
            .unwrap()
            .then((response) => {
              if (
                response?.CustomerAddress?.status === "success" &&
                response?.CustomerAddress?.code === "200"
              ) {
                dispatch(
                  fetchAddressList({
                    // address_id: formData?.address_id,
                    customer_id: userId?.id,
                    uniquesessionid: uniquesessionid,
                  })
                );
                SwalFireComponent({
                  icon: "success",
                  title: `${addressData?.address_id
                    ? "Address Update Successfully"
                    : "Address Saved Successfully"
                    }`,
                });
                setFormData({
                  country: "",
                  country_id: "",
                  countryCode: "",
                  Postcode: "",
                  firstName: "",
                  lastName: "",
                  companyName: "",
                  address01: "",
                  address02: "",
                  state: "",
                  city: "",
                  email: "",
                  contactNo: "",
                  contact_prefix: "",
                  address_type: selectedOption,
                  address_id: "",
                  addressInput: "",
                  state_sort_name: "",
                });
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                setShowForm(!showForm);
                setIsFormCollectionOpen(!isFormCollectionOpen);
              } else if (response?.CustomerAddress?.status === "fail" && response?.CustomerAddress?.code === "404") {
                setBackenderror((prevErrors) => ({
                  ...prevErrors,
                  firstName: response?.CustomerAddress?.errors?.first_name,
                  lastName: response?.CustomerAddress?.errors?.last_name,
                  address01: response?.CustomerAddress?.errors?.address_1,
                  city: response?.CustomerAddress?.errors?.city,
                  state: response?.CustomerAddress?.errors?.state,
                  contact_extention: response?.CustomerAddress?.errors?.contact_extention

                }))
              }
            })
            .catch((error) => {
              dispatch(
                fetchAddressList({
                  address_id: formData?.address_id,
                  customer_id: userId?.id,
                  uniquesessionid: uniquesessionid,
                })
              );
            });
        } else {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            country: "Please enter valid country",
          }));
        }
      } else {
        setStateError("This field is required");
      }
    }
  };

  useEffect(() => {
    if (userId) {
      dispatch(
        fetchAddressList({
          customer_id: userId?.id,
          uniquesessionid: uniquesessionid,
        })
      );
      dispatch(fetchCountryDetails());
      // dispatch(fetchStateName());
    }
  }, []);


  useEffect(() => {
    if (state_name || formData?.country_id) {
      setStatenames(state_name)
    }
  }, [state_name, formData?.country_id]);

  useEffect(() => {
    if (!isStateLoading && !statenames && formData?.state?.trim() === "") {
      setNewFormStateErrors("This field is required");
    } else {
      setNewFormStateErrors("");
    }
  }, [formData?.state]);


  useEffect(() => {
    if (formData?.country_id) {
      const from_Pincode_Format = country_Name?.filter(
        (data) => data.id === formData?.country_id
      );
      const count = from_Pincode_Format && from_Pincode_Format[0]?.postcode_format?.length;
      setFrompostalLength(count)
      const stateName = formData?.country_id;
      dispatch(fetchStateName(stateName))
        .unwrap()
        .then((response) => {
          if (formData?.address_id) {
            if (response?.data?.length > 0) {
              let statedata = response?.data?.find((i) => {
                return formData?.state === i?.code;
              });
              setFormData({
                ...formData,
                state: statedata.code,
                state_sort_name: statedata?.code,
              });
            } else {
              setFormData({
                ...formData,
                state: formData?.state,
                state_sort_name: formData?.state,
              });
            }
          } else {
            if (response?.data?.length > 0) {
              setFormData({
                ...formData,
                state: response?.data?.[0]?.code,
                state_sort_name: response?.data?.[0]?.code,
              });
            } else {
              setFormData({
                ...formData,
                state: "",
                state_sort_name: "",
              });
            }
          }
        })
        .catch((error) => { });
    }
  }, [formData?.country_id, dispatch]);

  useEffect(() => {
    setFilterAddress(listAddress);
  }, [listAddress]);

  useEffect(() => {
    if (country_Name) {
      const topCountries = country_Name?.filter(
        (selectedData) => selectedData?.top_countries === 1
      );
      const remainingCountries = country_Name?.filter(
        (selectedData) => selectedData?.top_countries !== 1
      );
      const filteredCountries = [...topCountries, ...remainingCountries];
      setCountryName(filteredCountries);
    }
    setStatenames("")
  }, [country_Name]);

  const toggleFormCollection = () => {
    setShowForm(!showForm);
    setStatenames("")
    setIsFormCollectionOpen(!isFormCollectionOpen);
    setFormData({
      ...formData,
      firstName: "",
      lastName: "",
      country: "",
      Postcode: "",
      companyName: "",
      address01: "",
      address02: "",
      state: "",
      city: "",
      email: "",
      contactNo: "",
      country_id: "",
      contact_prefix: "",
      contact_extention: "",
      address_id: "",
      addressInput: "",
      state_sort_name: "",
    });

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      country: "",
      Postcode: "",
    }));
    setBackenderror({})
    setNewFormStateErrors("")
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleMakeDefault = (address, selectedIndex) => {
    setDefaultId(address?.id);
    // const addressDelete = filteredAddress[selectedIndex]?.id
    const addressData = {
      address_id: address.id,
    };
    dispatch(
      fetchUserAddressDetails({
        addressData,
        store: `${addressData?.address_id ? "default" : "store"}`,
      })
    )
      .unwrap()
      .then((response) => {
        setDefaultId();
        if (
          response?.CustomerAddress?.status === "success" &&
          response?.CustomerAddress?.code === "200"
        ) {
          dispatch(
            fetchAddressList({
              address_id: formData?.address_id,
              customer_id: userId?.id,
              uniquesessionid: uniquesessionid,
            })
          )
            .unwrap()
            .then((response) => {
              if (bulkUserAddRow) {
                // handleCloseModal()
                setshowAddressModal(false);
              }
            });
          // SwalFireComponent({
          //   icon: "success",
          //   title: "Address Default set Successfully",
          // });
        }
        if (
          response?.CustomerAddress?.code === "404" &&
          response?.CustomerAddress?.status === "fail"
        ) {
          setDefaultId();
          SwalFireComponent({
            icon: "error",
            title: response?.CustomerAddress?.message,
          });
          dispatch(
            fetchAddressList({
              address_id: formData?.address_id,
              customer_id: userId?.id,
              uniquesessionid: uniquesessionid,
            })
          );
        }
      })
      .catch((error) => { });
  };

  const handleEditDetail = (address, selectedIndex, e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let data = country_Name?.find((i) => {
      return address?.country_id === i?.id;
    });
    // let statedata = statenames?.find((i) => {
    //   return address?.state === i?.code;
    // });
    // dispatch(resetErrors());
    setFormData({
      ...formData,
      // country: address?.country.name,
      country: data?.name,
      Postcode: address?.postcode,
      firstName: address?.first_name,
      lastName: address?.last_name,
      companyName: address?.company_name,
      address01: address?.address_1,
      address02: address?.address_2 ? address.address_2 : "",
      country_id: address?.country_id,
      city: address?.city,
      email: address?.email,
      contact_extention: address?.contact_extention,
      contactNo: address?.contact_no,
      address_type: selectedOption,
      countryCode: address?.country_code,
      address_id: address?.id,
      state: address?.state,
      state_sort_name: address?.state,
    });
    setSelectedOption(address?.address_type);
    setIsFormCollectionOpen(true);
    setShowForm(true ? true : true);
  };

  const handleDeleteAddress = (address) => {
    setDeleteID(address?.id);
    const addressData = {
      address_id: address.id,
      customer_id: userId?.id,
      uniquesessionid: uniquesessionid,
    };
    dispatch(fetchUserAddressDetails({ addressData, store: "delete" }))
      .unwrap()
      .then((response) => {
        setDeleteID();
        if (
          response?.CustomerAddress?.status === "success" &&
          response?.CustomerAddress?.code === "200"
        ) {
          dispatch(
            fetchAddressList({
              address_id: formData?.address_id,
              customer_id: userId?.id,
              uniquesessionid: uniquesessionid,
            })
          );
          // SwalFireComponent({
          //   icon: "success",
          //   title: "Address Deleted Successfully",
          // });
        }
        if (
          response?.CustomerAddress?.code === "404" &&
          response?.CustomerAddress?.status === "fail"
        ) {
          setDeleteID();
          SwalFireComponent({
            icon: "error",
            title: response?.CustomerAddress?.message,
          });
          dispatch(
            fetchAddressList({
              address_id: formData?.address_id,
              customer_id: userId?.id,
              uniquesessionid: uniquesessionid,
            })
          );
        }
      })
      .catch((error) => { });
  };
  const handleaddress = (e) => {
    setFormData({
      ...formData,
      addressInput: e.target.value,
    });
    if (e.target.value === "") {
      setFilterAddress(listAddress);
    }
  };
  const searchAddresses = () => {
    if (formData?.addressInput) {
      let address = listAddress?.filter(
        (address) =>
          address.address_1
            .toLowerCase()
            .includes(formData?.addressInput?.toLowerCase()) ||
          address.city
            .toLowerCase()
            .includes(formData?.addressInput?.toLowerCase()) ||
          address.state
            .toLowerCase()
            .includes(formData?.addressInput?.toLowerCase()) ||
          address.contact_no
            .toLowerCase()
            .includes(formData?.addressInput?.toLowerCase()) ||
          address.first_name
            .toLowerCase()
            .includes(formData?.addressInput?.toLowerCase()) ||
          address.last_name
            .toLowerCase()
            .includes(formData?.addressInput?.toLowerCase()) ||
          address.postcode
            .toLowerCase()
            .includes(formData?.addressInput?.toLowerCase())
      );
      setFilterAddress(address);
    } else {
      setFilterAddress(listAddress);
    }
  };
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleCountry = (setFieldValue, newValue) => {
    setFieldValue("postal", "");
    setFieldValue("country", newValue);
    if ((country_Name, newValue)) {
      const data = country_Name?.find((cntr) => cntr?.name === newValue);
      setFormData({
        ...formData,
        country_code: data?.code ? data?.code : "",
        country_id: data?.id,
        Postcode: "",
        state: "",
        state_sort_name: "",
      });
    }
  };
  const handlepostal = (setFieldValue, newInputValue) => {
    setFieldValue("postal", newInputValue);
    dispatch(
      fetchPostalDetails({
        country_id: formData?.country_id,
        search_pincode: newInputValue,
        uniquesessionid: uniquesessionid,
      })
    );
  };
  const handleSelectChange = (event) => {
    setStateError("");
    const selectedState = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      state_sort_name: selectedState,
      state: selectedState,
    }));
  };
  const importaddressfromexcel = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const fileInputRef = useRef(null);
  const handleFile = (e) => {
    setLineErrors("");
    setAllErrors("");
    let formData = new FormData();
    formData.append("address_file", e.target.files[0]);
    dispatch(
      bulkAddress({
        formData,
        store: `import`,
      })
    )
      .unwrap()
      .then((response) => {
        if (
          response?.CustomerAddress?.status === "success" &&
          response?.CustomerAddress?.code === "200"
        ) {
          SwalFireComponent({
            icon: "success",
            title: `${response?.CustomerAddress?.message}`,
          });
          handleCloseModal();
          dispatch(
            fetchAddressList({
              customer_id: userId?.id,
              uniquesessionid: uniquesessionid,
            })
          );
        }
        if (
          response?.CustomerAddress?.code === "500" &&
          response?.CustomerAddress?.status === "fail"
        ) {
          setLineErrors(response?.CustomerAddress?.errors);
        }
        if (
          response?.CustomerAddress?.code === "422" &&
          response?.CustomerAddress?.status === "fail"
        ) {
          setAllErrors(response?.CustomerAddress?.errors);
        }
      });
  };
  const handleButtonClick = () => {
    if (fileInputRef?.current) {
      fileInputRef?.current?.click();
    }
  };
  const formatKey = (key) =>
    key
      .split("_")
      .map((word, index) =>
        index === 0
          ? word.charAt(0).toUpperCase() + word.slice(1)
          : word.charAt(0).toLowerCase() + word.slice(1)
      )
      .join(" ") + ",";

  return (
    <div className={`user-address-outer ${bulkUserAddRow ? "mb-0" : "mb-5"}`}>
      <>
        <Card style={{ border: "none" }}>
          {bulkUserAddRow && (
            <p className="mb-3">
              To change your collection address, please select a default address
              or add a new address.
            </p>
          )}
          <Card.Body className={` ${bulkUserAddRow ? "p-0" : "p-4"}`}>
            <div>
              {!bulkUserAddRow && (
                <div className="mb-3 d-flex justify-content-between">
                  <div>
                    {!bulkUserAddRow && (
                      <div>
                        {isFormCollectionOpen ? (
                          <h5>
                            {formData?.address_id ? (
                              <>
                                Edit Address
                              </>
                            ) : (
                              <>
                                Add New Address
                              </>
                            )}
                          </h5>
                        ) : (
                          <h5>Address Book</h5>
                        )}
                      </div>
                    )}

                    {isFormCollectionOpen && bulkUserAddRow && (
                      <h5>Edit Address</h5>
                    )}
                  </div>

                  {!isFormCollectionOpen && (
                    <Button
                      variant="outlined"
                      className="outer_btn btn d-flex gap-3"
                      style={{ borderColor: "#113468", height: "40px" }}
                      onClick={importaddressfromexcel}
                    >
                      <img
                        src={`${window.location.origin}/assets/multishipment/msexcel.svg`}
                        alt="msexcel"
                      />
                      Import
                    </Button>
                  )}
                </div>
              )}
              <Modal
                show={showModal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                className="user-wallet-payment-modal d-flex justify-content-center align-items-center "
              >
                <Modal.Body className="p-0 overflow-auto d-flex align-items-start rounded-3">
                  <div
                    className="user-modal-wallet w-100 h-100"
                    style={{ backgroundColor: "white", borderRadius: "10px" }}
                  >
                    <Modal.Header
                      style={{ right: "0px" }}
                      closeButton
                      className="closebutton d-flex justify-content-end position-sticky  z-3 bg-white"
                    ></Modal.Header>
                    <div className="px-4 pb-4 d-flex flex-column gap-3">
                      <div className="d-flex flex-column gap-4">
                        <h5>Import Excel</h5>
                        <div className="d-flex justify-content-center gap-5 align-items-center">
                          <a
                            className=" blue-text"
                            href={`${window.location.origin}/assets/excelData/Address_Sample_File.xls`}
                            style={{ cursor: "pointer" }}
                          >
                            <div className=" d-flex gap-3 justify-content-center">
                              <img
                                src={`${window.location.origin}/assets/multishipment/file_save.svg`}
                                alt="file_save"
                              />
                              <span
                                className="paragraph1 blue-text text-nowrap text-decoration-none"
                                style={{ cursor: "pointer" }}
                              >
                                Download sample file
                              </span>
                            </div>
                          </a>
                        </div>
                        {!isBulkAddressLoading ? (
                          <>
                            <div className="d-flex justify-content-center ">
                              <Form className="" style={{ width: "80%" }}>
                                <div className="">
                                  <input
                                    ref={fileInputRef}
                                    type={"file"}
                                    id={"input-file-upload"}
                                    accept={
                                      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    }
                                    onChange={(e) => handleFile(e)}
                                  />
                                  <label
                                    id="label-file-upload"
                                    htmlFor="input-file-upload"
                                    className="doted_border doted_border-1 py-5"
                                  >
                                    <div className="w-50 gap-2 d-flex flex-column justify-content-center">
                                      <div className="text-center ">
                                        <img
                                          src={`${window.location.origin}/assets/multishipment/backup.svg`}
                                          alt="backup"
                                        />
                                      </div>
                                      <div>
                                        <span className="d-flex packaging-holder gap-1 justify-content-center ">
                                          <p className="text-nowrap ">
                                            Drag and drop
                                          </p>
                                          <img
                                            src={`${window.location.origin}/assets/multishipment/msexcel.svg`}
                                            alt="msexcel"
                                          />
                                          <p className="text-nowrap ">
                                            excel file here
                                          </p>
                                        </span>
                                      </div>
                                      <div>
                                        <span className="d-flex packaging-holder gap-2 align-items-center  justify-content-center ">
                                          <hr className="w-50 m-0" />
                                          <p>or</p>
                                          <hr className="w-50 m-0" />
                                        </span>
                                      </div>
                                      <div className="text-center">
                                        <Button
                                          className="primarybtn btn px-5 py-2"
                                          onClick={handleButtonClick}
                                        >
                                          Browse File
                                        </Button>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </Form>
                            </div>
                          </>
                        ) : (
                          <>
                            <Card className="border-0 order-details-card">
                              <Card.Body>
                                <Box className="w-100 justify-content-center d-flex ">
                                  <Skeleton
                                    variant="rounded"
                                    width={"84%"}
                                    height={256}
                                  />
                                </Box>
                              </Card.Body>
                            </Card>
                          </>
                        )}
                      </div>
                      {/* <p className="text-danger px-5 mx-5">{allErros}</p> */}

                      <div
                        className="text-danger px-5 mx-5"
                        ref={contentRef}
                        dangerouslySetInnerHTML={{ __html: allErros }}
                      />
                      {lineErrors && (
                        <div className="text-danger px-3">
                          {lineErrors.map((error, index) => (
                            <>
                              <div className="d-flex gap-2">
                                <div className="text-nowrap">
                                  In Row No. {error?.line_no + 2}
                                </div>
                                <div className="d-flex gap-1" key={index}>
                                  {Object.keys(error)
                                    .filter((key) => Array.isArray(error[key]))
                                    .map((key) => (
                                      <div className="text-nowrap" key={key}>
                                        {formatKey(key)}
                                      </div>
                                    ))}
                                </div>
                                <div>
                                  <div>is missing or invalid.</div>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              {!isFormCollectionOpen && (
                <Row className="user-address-row mb-3">
                  <Col sm={6} md={6} lg={6} xl={5}>
                    <Card className=" mb-2 card-border">
                      <div className={`address-bg d-flex px-4 card-add-address ${isFormCollectionOpen ? "open" : ""}`} onClick={toggleFormCollection} style={{ height: "50px" }}  >
                        <img src={`${window.location.origin}/assets/${isFormCollectionOpen ? "minusblue" : "plusblue"}.svg`} alt="Toggle Icon" />
                        {(!isFormCollectionOpen || isFormCollectionOpen) && (
                          <p className=" mb-0 px-2 d-flex align-items-center">
                            Add New Address
                          </p>
                        )}
                      </div>
                    </Card>
                  </Col>
                  <Col sm={6} md={6} lg={6} xl={5}>
                    <div class="input-group" style={{ height: "50px" }}>
                      <input
                        type="text"
                        class="form-control  user-address-placeholder py-2 is-valid-paper position-relative  user-address-padding"
                        name="addressInput"
                        placeholder="Enter your text"
                        onChange={handleaddress}
                        value={formData?.addressInput}
                        aria-label="Text input with button"
                      />
                      <Button
                        style={{
                          right: "5px",
                          top: "5px",
                          zIndex: "999",
                          borderRadius: "6px",
                          height: "40px",
                        }}
                        className="blue-text primarybtn btn savebtn-user position-absolute "
                        onClick={searchAddresses}
                      >
                        Search
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}

              {showForm && (
                <Col md="12" className="mb-2 ">
                  <Card className={` shadow-none border-0 user-address-form-card w-75 ${bulkUserAddRow ? "mb-0 py-0" : "mb-4 py-3"}`} style={{ borderRadius: "10px" }} >
                    <Card.Body className={`w-90 w-sm-100`} style={{ padding: "0px" }}>
                      <Formik
                        // enableReinitialize
                        initialValues={initialValues}
                        validationSchema={adressValidation}
                        onSubmit={Send}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                        }) => (
                          <Form onSubmit={handleSubmit}>
                            <Row className="mb-3 gap-3 gap-md-0 gap-sm-3 gap-lg-0 gap-xxl-0">
                              <Col md="6 " lg="5">
                                <Paper
                                  className={`my-paper-UserAddress ${(errors.country &&
                                    touched.country &&
                                    errors.country) ||
                                    formErrors?.country
                                    ? "is-invalid-paper"
                                    : "is-valid-paper"
                                    }`}
                                  style={{ borderRadius: "6px" }}
                                >
                                  <CustomAutocomplete
                                    value={formData.country}
                                    placeholder={"Country"}
                                    options={
                                      countryName &&
                                      countryName?.map((data) => data?.name)
                                    }
                                    // onChange={(event, newValue) => {
                                    //   dispatch(resetErrors(""));
                                    //   setFormErrors({
                                    //     ...formErrors,
                                    //     country: "",
                                    //   });
                                    //   handleCountry(setFieldValue, newValue);
                                    //   setFieldValue("postal", "");
                                    // }}
                                    onInputChange={(event, newInputValue) => {
                                      handleCountry(
                                        setFieldValue,
                                        newInputValue
                                      );
                                      if (newInputValue) {
                                        setFormErrors((prevErrors) => ({
                                          ...prevErrors,
                                          country: "",
                                        }));
                                      } else {
                                        setFormErrors((prevErrors) => ({
                                          ...prevErrors,
                                          country: "This field is required",
                                        }));
                                      }
                                    }}
                                    isInvalid={
                                      errors.country &&
                                      touched.country &&
                                      errors.country
                                    }
                                  />
                                </Paper>
                                {/* {errors.country && (
                                  <Form.Text className="text-danger mt-0">
                                    {errors.country &&
                                      touched.country &&
                                      errors.country}
                                  </Form.Text>
                                )} */}
                                {/* {errors?.country && (
                                  <Form.Text className="text-danger mt-0" type="invalid">
                                    {errors.country && touched.country && errors.country}
                                  </Form.Text>
                                )}
                                {formErrors?.country && (
                                  <Form.Text className="text-danger mt-0">
                                    {formErrors.country}
                                  </Form.Text>
                                )} */}
                                {errors?.country && touched.country ? (
                                  <Form.Text
                                    className="text-danger mt-0"
                                    type="invalid"
                                  >
                                    {errors.country}
                                  </Form.Text>
                                ) : (
                                  formErrors?.country && (
                                    <Form.Text className="text-danger mt-0">
                                      {formErrors.country}
                                    </Form.Text>
                                  )
                                )}
                              </Col>
                              <Col md="4" lg="3">
                                <Paper
                                  className={`my-paper-UserAddress ${(errors.postal &&
                                    touched.postal &&
                                    errors.postal) ||
                                    formErrors?.Postcode
                                    ? "is-invalid-paper"
                                    : "is-valid-paper"
                                    }`}
                                  style={{ borderRadius: "6px" }}
                                >
                                  <CustomAutocomplete
                                    value={values.postal}
                                    loading={isPostalLoading}
                                    placeholder={"Postal"}
                                    options={
                                      country_Postal &&
                                      country_Postal?.map(
                                        (pincodes) => pincodes?.pincode_no
                                      )
                                    }
                                    onInputChange={(event, newInputValue) => {
                                      handlepostal(
                                        setFieldValue,
                                        newInputValue
                                      );
                                      if (newInputValue) {
                                        setFormErrors((prevErrors) => ({
                                          ...prevErrors,
                                          Postcode: "",
                                        }));
                                      } else {
                                        setFormErrors((prevErrors) => ({
                                          ...prevErrors,
                                          Postcode: "This field is required",
                                        }));
                                      }
                                    }}
                                    lengthInput={frompostalLength ? frompostalLength : 10}
                                    // onBlur={(event) => {
                                    //   if (!event.target.value) {
                                    //     setFormErrors((prevErrors) => ({
                                    //       ...prevErrors,
                                    //       Postcode: "This field is required",
                                    //     }));
                                    //   }
                                    // }}
                                    isInvalid={
                                      errors.postal &&
                                      touched.postal &&
                                      errors.postal
                                    }
                                  />
                                </Paper>
                                {/* {errors?.postal && (
                                  <Form.Text className="text-danger mt-0" type="invalid">
                                    {errors.postal &&
                                      touched.postal &&
                                      errors.postal}
                                  </Form.Text>
                                )} */}
                                {/* {formErrors?.Postcode && (
                                  <Form.Text className="text-danger mt-0">
                                    {formErrors.Postcode}
                                  </Form.Text>
                                )} */}

                                {errors?.postal && touched.postal ? (
                                  <Form.Text
                                    className="text-danger mt-0"
                                    type="invalid"
                                  >
                                    {errors.postal}
                                  </Form.Text>
                                ) : (
                                  formErrors?.Postcode && (
                                    <Form.Text className="text-danger mt-0">
                                      {formErrors.Postcode}
                                    </Form.Text>
                                  )
                                )}
                              </Col>
                            </Row>
                            <Row className="mb-3 gap-3 gap-md-0 gap-sm-3 gap-lg-0">
                              <Col md="4">
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    name="firstName"
                                    className={`user-address-placeholder input-main-all my-paper-UserAddress ${(errors.firstName && touched.firstName && errors.firstName) || backenderror?.firstName ? "is-invalid-paper" : "is-valid-paper"}`}
                                    value={values.firstName}
                                    placeholder="First name"
                                    // onChange={handleChange}
                                    onChange={(e) => {
                                      setBackenderror((prev) => ({ ...prev, firstName: "" }));
                                      handleChange(e);
                                    }}
                                    onKeyPress={(e) => {
                                      if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      errors.firstName &&
                                      touched.firstName &&
                                      errors.firstName
                                    }
                                  />
                                  {/* <Form.Control.Feedback type="invalid">
                                    <span>
                                      {errors.firstName &&
                                        touched.firstName &&
                                        errors.firstName}
                                    </span>
                                  </Form.Control.Feedback> */}
                                  {errors?.firstName && touched.firstName ? (
                                    <Form.Text
                                      className="text-danger mt-0"
                                      type="invalid"
                                    >
                                      {errors.firstName}
                                    </Form.Text>
                                  ) : (
                                    backenderror?.firstName && (
                                      <Form.Text className="text-danger mt-0">
                                        {backenderror?.firstName}
                                      </Form.Text>
                                    )
                                  )}
                                </Form.Group>
                              </Col>
                              <Col md="4">
                                <Form.Group>
                                  <Form.Control
                                    className={`user-address-placeholder input-main-all my-paper-UserAddress ${(errors.lastName && touched.lastName && errors.lastName) || backenderror?.lastName ? "is-invalid-paper" : "is-valid-paper"}`}
                                    type="text"
                                    name="lastName"
                                    value={values.lastName}
                                    placeholder="Last name"
                                    // onChange={handleChange}
                                    onChange={(e) => {
                                      setBackenderror((prev) => ({ ...prev, lastName: "" }));
                                      handleChange(e);
                                    }}
                                    onKeyPress={(e) => {
                                      if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      errors.lastName &&
                                      touched.lastName &&
                                      errors.lastName
                                    }
                                  />
                                  {/* <Form.Control.Feedback type="invalid">
                                    <span>
                                      {errors.lastName &&
                                        touched.lastName &&
                                        errors.lastName}
                                    </span>
                                  </Form.Control.Feedback> */}


                                  {errors?.lastName && touched.lastName ? (
                                    <Form.Text
                                      className="text-danger mt-0"
                                      type="invalid"
                                    >
                                      {errors.lastName}
                                    </Form.Text>
                                  ) : (
                                    backenderror?.lastName && (
                                      <Form.Text className="text-danger mt-0">
                                        {backenderror?.lastName}
                                      </Form.Text>
                                    )
                                  )}
                                </Form.Group>
                              </Col>
                              <Col md="4">
                                <Form.Group>
                                  <Form.Control
                                    className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.companyName &&
                                      touched.companyName &&
                                      errors.companyName
                                      ? "is-invalid-paper"
                                      : "is-valid-paper"
                                      }`}
                                    type="text"
                                    name="companyName"
                                    value={values.companyName}
                                    placeholder="Company Name"
                                    onChange={handleChange}
                                    onKeyPress={(e) => {
                                      if (!/^[a-zA-Z\s.]*$/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    
                                    onBlur={handleBlur}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Form.Group>
                              <Row className="mb-3 gap-3 gap-lg-0">
                                <Col md="12">
                                  <Form.Control
                                    className={`user-address-placeholder input-main-all my-paper-UserAddress ${(errors.address01 && touched.address01 && errors.address01) || backenderror?.address01 ? "is-invalid-paper" : "is-valid-paper"}`}
                                    type="text"
                                    name="address01"
                                    value={values.address01}
                                    placeholder="Address01"
                                    // onChange={handleChange}
                                    onChange={(e) => {
                                      setBackenderror((prev) => ({ ...prev, address01: "" }));
                                      handleChange(e);
                                    }}
                                    onKeyPress={(e) => {
                                      if (!/^[a-zA-Z0-9\s,]*$/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    
                                    onBlur={handleBlur}
                                    isInvalid={
                                      errors.address01 &&
                                      touched.address01 &&
                                      errors.address01
                                    }
                                  />
                                  {/* <Form.Control.Feedback type="invalid">
                                    <span>
                                      {errors.address01 &&
                                        touched.address01 &&
                                        errors.address01}
                                    </span>
                                  </Form.Control.Feedback> */}

                                  {errors?.address01 && touched.address01 ? (
                                    <Form.Text
                                      className="text-danger mt-0"
                                      type="invalid"
                                    >
                                      {errors.address01}
                                    </Form.Text>
                                  ) : (
                                    backenderror?.address01 && (
                                      <Form.Text className="text-danger mt-0">
                                        {backenderror?.address01}
                                      </Form.Text>
                                    )
                                  )}
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group>
                              <Row className="mb-3 gap-3 gap-lg-0">
                                <Col md="12">
                                  <Form.Control
                                    className="user-address-placeholder input-main-all is-valid-paper my-paper-UserAddress"
                                    type="text"
                                    name="address02"
                                    value={values.address02}
                                    placeholder="Address02"
                                    onChange={handleChange}
                                    onKeyPress={(e) => {
                                      if (!/^[a-zA-Z0-9\s,]*$/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </Col>
                              </Row>
                            </Form.Group>
                            <Row className="mb-3 gap-3 gap-md-0 gap-sm-3 gap-lg-0">
                              <Col md="6" className="collect-form-address">
                                {!statenames && (
                                  <Form.Group>
                                    <Form.Control
                                      className={`user-address-placeholder input-main-all my-paper-UserAddress ${(errors.state && touched.state && errors.state) || newFormStateErrors || backenderror?.state || stateError !== "" ? "is-invalid-paper" : "is-valid-paper"}`}
                                      type="text"
                                      name="state"
                                      placeholder="State"
                                      value={formData.state}
                                      // value={values.state}
                                      onChange={(e) => {
                                        setNewFormStateErrors("")
                                        setBackenderror((prev) => ({ ...prev, state: "" }));
                                        // handleSelectChange(e);
                                        handleSelectChange(e);
                                      }}
                                      onKeyPress={(e) => {
                                        if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onBlur={handleBlur}
                                      // isInvalid={
                                      //   (errors.state && touched.state) || newFormStateErrors?.state || backenderror?.state ||
                                      //   stateError !== ""
                                      // }
                                      isInvalid={backenderror?.state || newFormStateErrors || stateError !== ""}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      <span>
                                        {stateError || newFormStateErrors || backenderror?.state}
                                      </span>
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                )}
                                {statenames && (
                                  <Form.Group controlId="formGridfirstname">
                                    <Form.Select
                                      aria-label="Default select example"
                                      placeholder="Select"
                                      onBlur={handleBlur}
                                      className={`Pickup-select-input shadow-none  ${(errors.state_sort_name &&
                                        touched.state_sort_name &&
                                        errors.state_sort_name) ||
                                        stateError !== ""
                                        ? "is-invalid-paper"
                                        : "is-valid-paper" &&
                                        formData?.state_sort_name ===
                                        "Select" &&
                                        "pickup-placeholder-select"
                                        }`}
                                      onChange={handleSelectChange}
                                      value={formData.state_sort_name}
                                      isInvalid={
                                        (errors.state_sort_name &&
                                          touched.state_sort_name &&
                                          errors.state_sort_name) ||
                                        stateError !== ""
                                      }
                                    >
                                      {statenames?.map((option, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={option?.code}
                                          >
                                            {option?.default_name}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                    {errors?.state && (
                                      <Form.Text
                                        className="text-danger mt-0"
                                        type="invalid"
                                      >
                                        {(errors.state_sort_name &&
                                          touched.state_sort_name &&
                                          errors.state_sort_name) ||
                                          stateError}
                                      </Form.Text>
                                    )}
                                  </Form.Group>
                                )}
                              </Col>
                              <Col md="6">
                                <Form.Group>
                                  <Form.Control
                                    className={`user-address-placeholder input-main-all my-paper-UserAddress ${(errors.city && touched.city && errors.city) || backenderror?.city ? "is-invalid-paper" : "is-valid-paper"}`}
                                    type="text"
                                    name="city"
                                    placeholder="Enter City"
                                    value={values.city}
                                    // onChange={handleChange}
                                    onChange={(e) => {
                                      setBackenderror((prev) => ({ ...prev, city: "" }));
                                      handleChange(e);
                                    }}
                                    onKeyPress={(e) => {
                                      if (!/^[a-zA-Z]$/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    isInvalid={errors.city && touched.city && errors.city} />
                                  {errors?.city && touched.city ? (
                                    <Form.Text
                                      className="text-danger mt-0"
                                      type="invalid"
                                    >
                                      {errors.city}
                                    </Form.Text>
                                  ) : (
                                    backenderror?.city && (
                                      <Form.Text className="text-danger mt-0">
                                        {backenderror?.city}
                                      </Form.Text>
                                    )
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="mb-4 gap-3 gap-md-0 gap-sm-3 gap-lg-0">
                              <Col md={6}>
                                <Form.Group>
                                  <Form.Control
                                    className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.email &&
                                      touched.email &&
                                      errors.email
                                      ? "is-invalid-paper"
                                      : "is-valid-paper"
                                      }`}
                                    type="text"
                                    name="email"
                                    value={values.email}
                                    placeholder="Enter Email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      errors.email &&
                                      touched.email &&
                                      errors.email
                                    }
                                  />

                                  <Form.Control.Feedback type="invalid">
                                    <span>
                                      {errors.email &&
                                        touched.email &&
                                        errors.email}
                                    </span>
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Row>
                                  <Col md={3} xs={3} className="pe-0">
                                    <Form.Control
                                      className={`user-address-placeholder input-main-all my-paper-UserAddress ${(errors.contact_extention && touched.contact_extention && errors.contact_extention) || backenderror?.contact_extention ? "is-invalid-paper" : "is-valid-paper"}`}
                                      type="number"
                                      name="contact_extention"
                                      value={values.contact_extention}
                                      placeholder="+91"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      onKeyPress={(e) => {
                                        if (!/^[0-9+]+$/.test(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                      
                                      isInvalid={
                                        errors.contact_extention &&
                                        touched.contact_extention &&
                                        errors.contact_extention
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      <span>
                                        {errors.contact_extention &&
                                          touched.contact_extention &&
                                          errors.contact_extention}
                                      </span>
                                    </Form.Control.Feedback>
                                  </Col>
                                  <Col md={9} xs={9}>
                                    <Form.Control
                                      className={`user-address-placeholder input-main-all my-paper-UserAddress ${errors.contactNo &&
                                        touched.contactNo &&
                                        errors.contactNo
                                        ? "is-invalid-paper"
                                        : "is-valid-paper"
                                        }`}
                                      type="number"
                                      placeholder="Contact No"
                                      name="contactNo"
                                      // onWheel={event => event.currentTarget.blur()}
                                      value={values.contactNo}
                                      onChange={handleChange}
                                      onKeyPress={(e) => {
                                        if (!/^[0-9]*$/.test(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                      pattern="[0-9]*"
                                      onBlur={handleBlur}
                                      isInvalid={
                                        errors.contactNo &&
                                        touched.contactNo &&
                                        errors.contactNo
                                      }
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      <span>
                                        {errors.contactNo &&
                                          touched.contactNo &&
                                          errors.contactNo}
                                      </span>
                                    </Form.Control.Feedback>
                                  </Col>
                                </Row>
                                <p className="profile_address-error">
                                  {addressErrors?.phone_number}
                                </p>
                              </Col>
                            </Row>

                            <div className="radiobtnAddress mb-3 text-nowrap d-flex flex-row align-content-center  gap-2  gap-lg-3 gap-sm-2">
                              <p className="me-1">Select address type : </p>
                              <div className="radio-holderaddress custom_radio d-flex align-content-center gap-2 flex-row text-nowrap">
                                <div className=" homeRadio d-flex flex-row gap-1 p-1">
                                  <input
                                    className="chek"
                                    type="radio"
                                    id="Residential"
                                    name="select"
                                    value="Residential"
                                    checked={selectedOption === "Residential"}
                                    onChange={handleOptionChange}
                                  />
                                  <label for="Residential">Residential</label>
                                </div>
                                <div className="d-flex flex-row gap-1 p-1 ">
                                  <input
                                    type="radio"
                                    id="Business"
                                    name="select"
                                    value="Business"
                                    checked={selectedOption === "Business"}
                                    onChange={handleOptionChange}
                                  />
                                  <label for="Business">Business</label>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex gap-2">
                              <Button
                                style={{ height: "40px" }}
                                className="blue-text primarybtn btn user-save-address"
                                type="submit"
                              >
                                {!isSaveLoading ? (
                                  <>
                                    {formData?.address_id ? (
                                      <>
                                        Update
                                      </>
                                    ) : (
                                      <>
                                        Save
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Spinner animation="border" size="sm" />
                                  </>
                                )}
                              </Button>
                              {/* {!bulkUserAddRow && ( */}
                              <Button
                                variant="link"
                                className="primary_btn_link btn reset-btn"
                                onClick={toggleFormCollection}
                              >
                                Cancel
                              </Button>
                              {/* )} */}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </Card.Body>
                  </Card>
                </Col>
              )}

              <div className="d-flex flex-wrap gap-2">
                {isAddressLoading ? (
                  <>
                    <UserSkeleton
                      width="83%"
                      twoLine={false}
                      tworound={false}
                    />
                  </>
                ) : (
                  <>
                    {filteredAddress && filteredAddress?.length && !showForm ? (
                      <>
                        <div className="w-100">
                          <div className="d-flex flex-wrap gap-4 flex-column flex-sm-row flex-md-row">
                            {filteredAddress?.map((address, index) => (
                              <div
                                className="mapped-cards d-flex "
                                key={index}
                                style={{ flex: "0 0 50%", maxWidth: "40.3%" }}
                              >
                                <Card
                                  className={`h-100 w-100 useraddress_card ${address?.is_default === "Yes"
                                    ? "backgrounddefault selectedCard"
                                    : "card-border"
                                    }`}
                                  onClick={() =>
                                    address?.is_default !== "Yes"
                                      ? handleMakeDefault(address, index)
                                      : null
                                  }
                                  selected={
                                    (address.id === address?.is_default) ===
                                    "Yes"
                                  }
                                >
                                  {bulkUserAddRow ? (
                                    <Tooltip
                                      title="Select this address"
                                      className="pointer-icon"
                                    >
                                      <Card.Body>
                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex gap-1">
                                            <div
                                              className="default-address-label text-center height-25 d-flex align-items-center px-1"
                                              style={{
                                                color: "#113468",
                                                backgroundColor: "#b5c0d0",
                                                inlineSize: "min-content",
                                                borderRadius: "3px",
                                              }}
                                            >
                                              {address?.address_type ===
                                                "Residential" ? (
                                                <>
                                                  <HomeRoundedIcon className="addressIconLabel" />
                                                </>
                                              ) : (
                                                <>
                                                  <CorporateFareRoundedIcon className="addressIconLabel" />
                                                </>
                                              )}
                                            </div>
                                            {address?.is_default === "Yes" && (
                                              <div
                                                className="default-address-label d-flex align-items-center px-2 text-center height-25"
                                                style={{
                                                  color: "#113468",
                                                  backgroundColor: "#b5c0d0",
                                                  inlineSize: "min-content",
                                                  borderRadius: "3px",
                                                }}
                                              >
                                                <span className="font-sm">
                                                  Default
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <Card.Text>
                                          <p
                                            style={{
                                              margin: "0px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {`${address.first_name} ${address.last_name
                                              } ${address?.contact_no !== null
                                                ? address?.contact_no
                                                : ""
                                              }`}
                                          </p>
                                          <p className="m-0">
                                            {address.address_1}
                                            <br />
                                            {address.city}, {address.state}{" "}
                                            {address.postcode},
                                            {` ${address.country.code}`}
                                          </p>
                                        </Card.Text>
                                      </Card.Body>
                                    </Tooltip>
                                  ) : (
                                    <Card.Body>
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex gap-1">
                                          <div
                                            className="default-address-label text-center height-25 d-flex align-items-center px-1"
                                            style={{
                                              color: "#113468",
                                              backgroundColor: "#b5c0d0",
                                              inlineSize: "min-content",
                                              borderRadius: "3px",
                                            }}
                                          >
                                            {address?.address_type ===
                                              "Residential" ? (
                                              <>
                                                <HomeRoundedIcon className="addressIconLabel" />
                                              </>
                                            ) : (
                                              <>
                                                <CorporateFareRoundedIcon className="addressIconLabel" />
                                              </>
                                            )}
                                          </div>
                                          {address?.is_default === "Yes" && (
                                            <div
                                              className="default-address-label d-flex align-items-center px-2 text-center height-25"
                                              style={{
                                                color: "#113468",
                                                backgroundColor: "#b5c0d0",
                                                inlineSize: "min-content",
                                                borderRadius: "3px",
                                              }}
                                            >
                                              <span className="font-sm">
                                                Default
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <Card.Text>
                                        <p
                                          style={{
                                            margin: "0px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {`${address.first_name} ${address.last_name
                                            } ${address?.contact_no !== null
                                              ? address?.contact_no
                                              : ""
                                            }`}
                                        </p>
                                        <p className="m-0">
                                          {address.address_1}
                                          <br />
                                          {address.city}, {address.state}{" "}
                                          {address.postcode},
                                          {` ${address.country.code}`}
                                        </p>
                                      </Card.Text>
                                    </Card.Body>
                                  )}
                                </Card>

                                <Card
                                  className={`d-flex flex-column justify-content-between p-2 useraddress_iconcard ${address?.is_default === "Yes"
                                    ? "backgrounddefault selectedCardIcon"
                                    : "card-border"
                                    }`}
                                >
                                  <div className="d-flex align-items-center justify-content-center">
                                    <Tooltip
                                      title={
                                        address?.is_default === "Yes"
                                          ? "Default"
                                          : "Make Default"
                                      }
                                      className="pointer-icon"
                                    >
                                      <IconButton
                                        className="p-0"
                                        onClick={
                                          address?.is_default !== "Yes"
                                            ? () =>
                                              handleMakeDefault(
                                                address,
                                                index
                                              )
                                            : null
                                        }
                                      >
                                        {defaultLoading &&
                                          address?.id === DefaultId &&
                                          !deleteId ? (
                                          <>
                                            <Spinner
                                              animation="border"
                                              className="userParcel-spinner"
                                              size="sm"
                                            />
                                          </>
                                        ) : (
                                          <>
                                            {address?.is_default === "Yes" ? (
                                              <>
                                                <CheckBoxIcon
                                                  className="lsnIcon"
                                                  style={{ color: "113468" }}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <CheckBoxOutlineBlankIcon className="pointer-icon icon-color lsnIcon" />
                                              </>
                                            )}
                                          </>
                                        )}
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                  <div className="">
                                    <Tooltip
                                      title="Edit"
                                      className="pointer-icon"
                                    >
                                      <IconButton
                                        className="p-0"
                                        onClick={() =>
                                          handleEditDetail(address, index)
                                        }
                                      >
                                        <>
                                          <img
                                            className="pointer-icon"
                                            alt="icon"
                                            src={`${window.location.origin}/assets/address-icons/edit.svg`}
                                          />
                                        </>
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                  <div className="">
                                    <Tooltip
                                      title="Delete"
                                      className="pointer-icon"
                                    >
                                      <IconButton
                                        className="p-0 w-100 "
                                        onClick={() =>
                                          address.id === deleteId &&
                                            deleteLoading
                                            ? null
                                            : handleDeleteAddress(
                                              address,
                                              index
                                            )
                                        }
                                      >
                                        {deleteLoading &&
                                          address.id === deleteId ? (
                                          <>
                                            <Spinner
                                              animation="border"
                                              className="userParcel-spinner"
                                              size="sm"
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              className="pointer-icon"
                                              alt="icon"
                                              src={`${window.location.origin}/assets/address-icons/delete.svg`}
                                            />
                                          </>
                                        )}
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </Card>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {!showForm && (
                          <>
                            <Card as={Col} className="col-lg-4 col-md-12">
                              <Card.Body>
                                <Card.Text>No Address Saved..</Card.Text>
                              </Card.Body>
                            </Card>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </Card.Body>
        </Card>
      </>
    </div>
  );
};

export default AddressCard;
